import "./bootstrap";
import "@/assets/scss/app.scss";

import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import BootstrapVueNext from "bootstrap-vue-next";
import store from "@/store";
import filters from "@/filters";
import confirmPlugin from "@/plugins/confirmPlugin";
import alertPlugin from "@/plugins/alertPlugin";
import { vuetify } from "@/plugins/vuetify";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(BootstrapVueNext);
app.use(vuetify);
app.use(confirmPlugin);
app.use(alertPlugin);
app.mount("#app");

app.config.globalProperties.$filters = filters;
