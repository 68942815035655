// import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";

// // Vuetifyのインスタンスを作成
export const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: "mdi", // This is already the default value - only for display purposes
	},
	// ssr: false,
	// icons: {
	// 	iconfont: "mdiSvg",
	// 	// defaultSet: "mdi",
	// },
	// icons: {
	// 	defaultSet: "mdi",
	// 	icons: mdi,
	// },
	theme: {
		themes: {
			light: {
				colors: {
					primary: "#71bbff",
					"on-primary": "#FFFFFF", // プライマリーボタンのテキストカラー
					// secondary: '#424242',
					// accent: '#82B1FF',
					// success: '#4CAF50',
					// warning: '#FFC107',
					error: "#ef5a4c",
					// "on-error": "#ff0000",
					// info: '#2196F3',
				},
			},
		},
	},
});
