<template>
	<v-dialog max-width="400px" v-model="show">
		<v-card>
			<v-card-title>{{ title }}</v-card-title>
			<v-card-text>{{ message }}</v-card-text>
			<v-card-actions>
				<v-btn color="default" variant="text" @click="$emit('cancel')"
					>閉じる</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		title: String,
		message: String,
		isVisible: Boolean,
	},
	data() {
		return {
			show: true,
		};
	},
};
</script>
