// confirmPlugin.js
import { createApp, h } from "vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { vuetify } from "./vuetify";

export default {
	install(app) {
		app.config.globalProperties.$confirm = function ({
			message,
			title = "確認",
			okButtonLabel = "OK",
			okButtonColor = "primary",
		}) {
			return new Promise((resolve) => {
				const confirmApp = createApp({
					data() {
						return {
							isVisible: true,
							message,
							title,
							okButtonLabel,
							okButtonColor,
						};
					},
					methods: {
						onConfirm() {
							resolve(true);
							this.closeDialog();
						},
						onCancel() {
							resolve(false);
							this.closeDialog();
						},
						closeDialog() {
							this.isVisible = false;
							setTimeout(() => confirmApp.unmount(), 0);
						},
					},
					render() {
						return this.isVisible
							? h(ConfirmDialog, {
									title: this.title,
									message: this.message,
									okButtonLabel: this.okButtonLabel,
									okButtonColor: this.okButtonColor,

									isVisible: this.isVisible,
									onConfirm: this.onConfirm,
									onCancel: this.onCancel,
							  })
							: null;
					},
				});

				// Vuetifyをプラグインとして追加
				confirmApp.use(vuetify);

				const container = document.createElement("div");
				document.body.appendChild(container);
				confirmApp.mount(container);
			});
		};
	},
};
